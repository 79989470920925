@import '~app/mixins';

.prelim {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  .popup {
    width: 460px;
    background-color: white;
    color: $color-grey;
    padding: 30px;
    line-height: 21px;
    box-shadow: 5px 3px 44px rgba(0, 0, 0, 0.16);

    .logo {
      text-align: center;
      margin-bottom: 30px;

      img {
        height: 80px;
      }
    }

    .error {
      color: red;
      margin-bottom: 15px;
      white-space: pre-line;
    }

    .terms {
      display: flex;
      justify-content: center;

      label span {
        font-size: 16px;

        a {
          cursor: pointer;
          color: #e64f15;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-around;
      margin-top: 30px;

      button {
        width: 125px;
      }
    }
  }

  &.loggedOut {
    .message {
      text-align: center;
    }
  }
}
